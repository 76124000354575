import * as request from '@/packages/api/helpers';

export type ConfigTTSProviderVoiceType = {
  id?: string;
  name?: string;
  voiceId?: string;
  modelId?: string;
  stability?: string;
  similarityBoost?: string;
  styleExageration?: string;
  boost?: 'on' | 'off';
  providerId?: string;
  pronunciationDictionaryId: string;
  pronunciationDictionaryVersionId: string;
};

export type ConfigTTSProviderType = {
  id?: string;
  provider?: string;
  key?: string;
  voices?: ConfigTTSProviderVoiceType[];
};

export type UpdateConfigTTSProviderPayload = {} & ConfigTTSProviderType;
export type PostConfigTTSProviderVoicePayload = {} & ConfigTTSProviderVoiceType;
export type UpdateConfigTTSProviderVoicePayload = {} & ConfigTTSProviderVoiceType;
export type PostConfigTTSProviderVoiceResponse = { id: string };
export type PostConfigTTSProviderResponse = { id: string };

export type PostConfigTTSProviderPayload = {
  key: string;
  provider: string;
};

export type GetConfigTTSProvidersResponse = {
  providers: ConfigTTSProviderType[];
};

export type GetConfigTTSVoicePayload = { id: string };
export type GetConfigTTSVoiceResponse = {} & ConfigTTSProviderVoiceType;
export type GetConfigTTSProviderPayload = { id: string };
export type GetConfigTTSProviderResponse = {} & ConfigTTSProviderType;

export const tts = {
  /**
   * Get tts provider
   */
  getVoice: async (payload: GetConfigTTSVoicePayload) => await request.get<GetConfigTTSVoiceResponse>('/api/config/tts/voice', payload, { next: { tags: ['config:tts'] } }),
  /**
   * Get tts provider
   */
  getProvider: async (payload: GetConfigTTSProviderPayload) => await request.get<GetConfigTTSProviderResponse>('/api/config/tts/provider', payload, { next: { tags: ['config:tts'] } }),
  /**
   * Get tts voice
   */
  getAllProviders: async () => await request.get<GetConfigTTSProvidersResponse>('/api/config/tts/provider/list', undefined, { next: { tags: ['config:tts'] } }),
  /**
   * Update provider
   */
  updateProvider: async (payload: UpdateConfigTTSProviderPayload) => await request.put(`/api/config/tts/provider`, payload, { invalidateTags: ['config:tts'] }),
  /**
   * Update voice
   */
  updateVoice: async (payload: UpdateConfigTTSProviderVoicePayload) => await request.put(`/api/config/tts/voice`, payload, { invalidateTags: ['config:tts'] }),
  /**
   * Create provider
   */
  createProvider: async (payload: PostConfigTTSProviderPayload) => await request.post<PostConfigTTSProviderResponse>(`/api/config/tts/provider`, payload, { invalidateTags: ['config:tts'] }),
  /**
   * Create voice
   */
  createVoice: async (payload: PostConfigTTSProviderVoicePayload) => await request.post<PostConfigTTSProviderVoiceResponse>(`/api/config/tts/voice`, payload, { invalidateTags: ['config:tts'] }),
};
