import * as request from '@/packages/api/helpers';

export type ConfigAPIKeyType = {
  id: string;
  name: string;
  key: string;
  active: boolean;
};

export type GetConfigAPIKeyPayload = { id: string };
export type GetConfigAPIKeyResponse = {} & ConfigAPIKeyType;
export type GetConfigAPIKeysResponse = {
  apiKeys: Array<ConfigAPIKeyType>;
};
export type PostAPIKeyPayload = {
  name: string;
};
export type PostAPIKeyResponse = {} & ConfigAPIKeyType;
export type DeleteConfigAPIKeyPayload = { id: string };

export const apiKey = {
  /**
   * Get API key
   */
  get: async (payload: GetConfigAPIKeyPayload) => await request.get<GetConfigAPIKeyResponse>('/api/config/api-key', payload, { next: { tags: ['config:api-key'] } }),
  /**
   * Get API keys
   */
  getAll: async () => await request.get<GetConfigAPIKeysResponse>('/api/config/api-key/list', undefined, { next: { tags: ['config:api-key'] } }),
  /**
   * Create an api key
   */
  create: async (payload: PostAPIKeyPayload) => await request.post<PostAPIKeyResponse>(`/api/config/api-key`, payload, { invalidateTags: ['config:api-key'] }),
  /**
   * Delete an api key
   */
  delete: async (payload: DeleteConfigAPIKeyPayload) => await request.del(`/api/config/api-key`, payload, { invalidateTags: ['config:api-key'] }),
};
