import { UserType } from '@/app/(pages)/account/types';
import * as request from '@/packages/api/helpers';
import { ConfigReviewGroupType } from './config/reviewGroup';

export type GetMeResponse = {} & UserType;

export type GetUserGroupsResponse = {
  reviewGroups: Array<ConfigReviewGroupType>;
};

export const me = {
  /**
   * Get user info
   */
  get: async () => await request.get<GetMeResponse>(`/api/me`, undefined, { next: { tags: ['user'] } }),
  /**
   * Get user review group actions
   */
  reviewGroups: async () => await request.get<GetUserGroupsResponse>(`/api/me/review-groups`, undefined, { next: { tags: ['user'] } }),
};
