import * as request from '@/packages/api/helpers';
import { ConfigUserRoleType } from './constants';
import { CampaignSpotApprovalStatusType } from '../campaign';

const TAG = `config:review-group`;

export type ConfigReviewGroupActionType = {
  id: string;
  action: string;
  status: CampaignSpotApprovalStatusType;
};

export type ConfigReviewGroupLevelUserRoleOptionType = {
  id?: string;
  status?: CampaignSpotApprovalStatusType;
  reviewLevelActionIds?: number[];
  reviewLevelActions?: ConfigReviewGroupActionType[];
};

export type ConfigReviewGroupLevelUserRoleType = {
  id?: string;
  userRole?: ConfigUserRoleType;
  options?: Array<ConfigReviewGroupLevelUserRoleOptionType>;
};

export type ConfigReviewGroupLevelType = {
  id?: string;
  name?: string;
  order?: number;
  userRoles?: Array<ConfigReviewGroupLevelUserRoleType>;
};

export type ConfigReviewGroupType = {
  id?: string;
  name?: string;
  order?: number;
  type?: 'Spot';
  reviewLevels?: Array<ConfigReviewGroupLevelType>;
};

export type GetReviewGroupPayload = { id: string };
export type GetReviewGroupResponse = {} & ConfigReviewGroupType;
export type GetReviewGroupsResponse = { reviewGroups: Array<ConfigReviewGroupType> };
export type PostReviewGroupPayload = {} & ConfigReviewGroupType;
export type PostReviewGroupResponse = { id: string };

export type GetReviewGroupLevelPayload = { id: string };
export type GetReviewGroupLevelsPayload = { reviewGroupId: string };
export type GetReviewGroupLevelResponse = {} & ConfigReviewGroupLevelType;
export type GetReviewGroupLevelsResponse = { reviewLevels: Array<ConfigReviewGroupLevelType> };
export type DeleteReviewGroupLevelPayload = { id: string };
export type UpdateReviewGroupLevelPayload = {} & ConfigReviewGroupLevelType;
export type UpdateReviewGroupLevelResponse = {} & ConfigReviewGroupLevelType;
export type PostReviewGroupLevelPayload = { reviewGroupId: string } & ConfigReviewGroupLevelType;
export type PostReviewGroupLevelResponse = {} & ConfigReviewGroupLevelType;
export type GetReviewGroupLevelUserRolePayload = { id: string };
export type GetReviewGroupLevelUserRoleResponse = {} & ConfigReviewGroupLevelUserRoleType;
export type PostReviewGroupLevelUserRolePayload = { reviewLevelId: string } & ConfigReviewGroupLevelUserRoleType;
export type UpdateReviewGroupLevelUserRolePayload = { id: string } & ConfigReviewGroupLevelUserRoleType;
export type GetReviewGroupActionPayload = { id: string };
export type GetReviewGroupActionResponse = {} & ConfigReviewGroupActionType;
export type GetReviewGroupActionsResponse = {
  reviewLevelActions: Array<ConfigReviewGroupActionType>;
};
export type PostReviewGroupActionPayload = {
  action: string;
  status: string;
};
export type PostReviewGroupActionResponse = {} & ConfigReviewGroupActionType;
export type UpdateReviewGroupActionPayload = {} & ConfigReviewGroupActionType;
export type UpdateReviewGroupActionResponse = {} & ConfigReviewGroupActionType;

export type GetReviewGroupLevelUserRoleOptionPayload = { id: string };
export type GetReviewGroupLevelUserRoleOptionResponse = {} & ConfigReviewGroupLevelUserRoleOptionType;
export type PostReviewGroupLevelUserRoleOptionPayload = { reviewLevelUserRoleId: string } & ConfigReviewGroupLevelUserRoleOptionType;
export type UpdateReviewGroupLevelUserRoleOptionPayload = { id: string; reviewLevelActionIds: number[] };
export type UpdateReviewGroupLevelUserRoleOptionResponse = {} & ConfigReviewGroupLevelUserRoleOptionType;

export const reviewGroup = {
  /**
   * Get review group
   */
  get: async (payload: GetReviewGroupPayload) => await request.get<GetReviewGroupResponse>('/api/config/review-group', payload, { next: { tags: [TAG] } }),
  /**
   * Get all review groups
   */
  getAll: async () => await request.get<GetReviewGroupsResponse>('/api/config/review-group/list', undefined, { next: { tags: [TAG] } }),
  /**
   * Create review group
   */
  create: async (payload: PostReviewGroupPayload) => await request.post<PostReviewGroupResponse>('/api/config/review-group', payload, { invalidateTags: [TAG] }),

  level: {
    get: async (payload: GetReviewGroupLevelPayload) => await request.get<GetReviewGroupLevelResponse>('/api/config/review-group/level', payload, { next: { tags: [TAG] } }),
    getAll: async (payload: GetReviewGroupLevelsPayload) => await request.get<GetReviewGroupLevelsResponse>('/api/config/review-group/level/list', payload, { next: { tags: [TAG] } }),
    delete: async (payload: DeleteReviewGroupLevelPayload) => await request.del('/api/config/review-group/level', payload, { invalidateTags: [TAG] }),
    update: async (payload: UpdateReviewGroupLevelPayload) => await request.put<UpdateReviewGroupLevelResponse>('/api/config/review-group/level', payload, { invalidateTags: [TAG] }),
    create: async (payload: PostReviewGroupLevelPayload) => await request.post<PostReviewGroupLevelResponse>('/api/config/review-group/level', payload, { invalidateTags: [TAG] }),

    userRole: {
      get: async (payload: GetReviewGroupLevelUserRolePayload) =>
        await request.get<GetReviewGroupLevelUserRoleResponse>('/api/config/review-group/level/user-role', payload, { next: { tags: [TAG] } }),
      create: async (payload: PostReviewGroupLevelUserRolePayload) => await request.post('/api/config/review-group/level/user-role', payload, { invalidateTags: [TAG] }),
      update: async (payload: UpdateReviewGroupLevelUserRolePayload) => await request.put('/api/config/review-group/level/user-role', payload, { invalidateTags: [TAG] }),

      options: {
        get: async (payload: GetReviewGroupLevelUserRoleOptionPayload) =>
          await request.get<GetReviewGroupLevelUserRoleOptionResponse>('/api/config/review-group/level/user-role/option', payload, { next: { tags: [TAG] } }),
        create: async (payload: PostReviewGroupLevelUserRoleOptionPayload) => await request.post('/api/config/review-group/level/user-role/option', payload, { invalidateTags: [TAG] }),
        update: async (payload: UpdateReviewGroupLevelUserRoleOptionPayload) =>
          await request.put<UpdateReviewGroupLevelUserRoleOptionResponse>('/api/config/review-group/level/user-role/option', payload, { invalidateTags: [TAG] }),
      },
    },
  },

  action: {
    get: async (payload: GetReviewGroupActionPayload) => await request.get<GetReviewGroupActionResponse>('/api/config/review-group/action', payload, { next: { tags: [TAG] } }),
    getAll: async () => await request.get<GetReviewGroupActionsResponse>('/api/config/review-group/action/list', undefined, { next: { tags: [TAG] } }),
    update: async (payload: UpdateReviewGroupActionPayload) => await request.put<UpdateReviewGroupActionResponse>('/api/config/review-group/action', payload, { invalidateTags: [TAG] }),
    create: async (payload: PostReviewGroupActionPayload) => await request.post<PostReviewGroupActionResponse>('/api/config/review-group/action', payload, { invalidateTags: [TAG] }),
  },
};
