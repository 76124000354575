import * as request from '@/packages/api/helpers';

export type PostWorkfrontRequestResponse = {
  campaignGroupId: string;
  releaseId: string;
};

export const release = {
  /**
   * Release
   */
  request: async (campaignGroupId: string) =>
    await request.post<PostWorkfrontRequestResponse>(`/api/release/request`, {
      campaignGroupId,
    }),
};
