import * as request from '@/packages/api/helpers';
import { CampaignSpotApprovalStatusType } from '../campaign';

export type ConfigUserRoleType = 'NotSet' | 'SuperAdmin' | 'Admin' | 'Editor' | 'Reviewer';

export type GetConfigConstantsResponse = {
  roles: Array<ConfigUserRoleType>;
  approvalStatus: Array<CampaignSpotApprovalStatusType>;
};

export const constants = {
  /**
   * Constants
   */
  get: async () => await request.get<GetConfigConstantsResponse>('/api/config/constants', undefined, { next: { tags: ['config:constants'] } }),
};
