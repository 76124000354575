import { release } from './release';
import { config } from './config';
import { media } from './media';
import { trackHistory } from './track-history';
import { track } from './track';
import { spot } from './spot';
import { me } from './me';
import { auth } from './auth';
import { user } from './user';
import { campaign } from './campaign';

const v1 = {
  auth,
  me,
  user,
  campaign,
  spot,
  track,
  trackHistory,
  media,
  config,
  release,
};

export default v1;
