import * as request from '@/packages/api/helpers';

export type AuthLoginOptions = {
  userName: string;
  password: string;
};

export type AuthLoginResponse = {
  tokenType: string;
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
};

export const auth = {
  /**
   * User auth
   */
  login: async (payload: AuthLoginOptions) => await request.post<AuthLoginResponse>(`/api/auth/login`, payload, { invalidateTags: ['user'] }),
};
