import * as request from '@/packages/api/helpers';

export const enum AudioTemplateTrackType {
  TextToSpeech = 'TextToSpeech',
  Audio = 'Audio',
}

export type ConfigAudioTemplateTrackType = {
  id: string;
  name?: string;
  order?: number;
  adelay?: number;
  duration?: {
    durationMax: number;
    durationMin: number;
  };
  controls?: {
    controlsVolume: boolean;
    controlsDownload: boolean;
    controlsATempo: boolean;
    controlsADelay: boolean;
  };
  type?: AudioTemplateTrackType;
  ttsVoiceId?: string | number;
  ttsScriptKey?: string;
  automaticADelayTrackId?: string;
  isAutomaticADelay?: boolean;
  ttsScriptExtractionKey?: string;
  isReusable?: boolean;
};

export type ConfigAudioTemplateType = {
  id?: string;
  name?: string;
  description?: string;
  filters?: string;
  output?: string;
  tracks?: ConfigAudioTemplateTrackType[];
};

export type GetConfigAudioTemplatePayload = {
  id: string;
};

export type GetConfigAudioTemplateTrackPayload = { id: string };
export type GetConfigAudioTemplateTrackResponse = {} & ConfigAudioTemplateTrackType;
export type PostConfigAudioTemplateTrackPayload = {} & ConfigAudioTemplateTrackType;
export type UpdateConfigAudioTemplateTrackPayload = {} & ConfigAudioTemplateTrackType;
export type GetConfigAudioTemplateResponse = {} & ConfigAudioTemplateType;
export type GetConfigAudioTemplatesResponse = {
  templates: ConfigAudioTemplateType[];
};
export type UpdateConfigAudioTemplatePayload = {} & ConfigAudioTemplateType;
export type PostConfigAudioTemplatePayload = {} & ConfigAudioTemplateType;

export const audioTemplate = {
  /**
   * Get audio template by ID
   */
  get: async (payload: GetConfigAudioTemplatePayload) =>
    await request.get<GetConfigAudioTemplateResponse>('/api/config/audio-template', { id: payload.id }, { next: { tags: ['config:audio-template'] } }),
  /**
   * Get audio template track
   */
  getTrack: async (payload: GetConfigAudioTemplateTrackPayload) =>
    await request.get<GetConfigAudioTemplateTrackResponse>('/api/config/audio-template/track', payload, { next: { tags: ['config:audio-template'] } }),
  /**
   * Get audio template list
   */
  getAll: async () => await request.get<GetConfigAudioTemplatesResponse>('/api/config/audio-template/list', undefined, { next: { tags: ['config:audio-template'] } }),
  /**
   * Update audio template
   */
  update: async (payload: UpdateConfigAudioTemplatePayload) => await request.put(`/api/config/audio-template`, payload, { invalidateTags: ['config:audio-template'] }),
  /**
   * Update audio template track
   */
  updateTrack: async (payload: UpdateConfigAudioTemplateTrackPayload) => await request.put(`/api/config/audio-template/track`, payload, { invalidateTags: ['config:audio-template'] }),
  /**
   * Create audio template
   */
  create: async (payload: PostConfigAudioTemplatePayload) => await request.post(`/api/config/audio-template`, payload, { invalidateTags: ['config:audio-template'] }),
  /**
   * Create audio template track
   */
  createTrack: async (payload: PostConfigAudioTemplateTrackPayload) => await request.post(`/api/config/audio-template/track`, payload, { invalidateTags: ['config:audio-template'] }),
};
