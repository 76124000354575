import * as request from '@/packages/api/helpers';

export type MediaAssetFileType = {
  base64: string;
  fileName: string;
  extension: string;
  duration: number;
  audioBitrate: number;
  audioChannels: number;
  audioSampleRate: number;
};

export type GetMediaTrackResponse = {
  trackId: string;
  trackType: string;
  name: string;
  text: string;
  delay: number;
  duration: number;
  url: string;
  totalComments: number;
  spotReviewStatus: string;
  createdAt: string;
  modifiedAt: string;
};

export type GetMediaAssetOptions = {
  assetId: string;
};

export type PostMediaResponse = {
  assetId: string;
};

export type GetMediaTrackOptions = {
  trackId: string;
};

export type GetMediaResponse = {
  foo: string;
};

export type GetMediaAssetResponse = {
  assetId: string;
  file: MediaAssetFileType;
};

export const media = {
  /**
   * Get a media file
   */
  get: async () => await request.get<GetMediaResponse>('/api/media', undefined, { next: { revalidate: 0 } }),

  /**
   * Get asset
   */
  asset: async (payload: GetMediaAssetOptions) => await request.get<GetMediaAssetResponse>(`/api/media/asset/${payload.assetId}`, undefined, { next: { revalidate: 0 } }),

  /**
   * Streaming
   */
  stream: (assetId: string) => `${String(process.env.NEXT_PUBLIC_API_HOST_URL)}/api/media/asset/${assetId}/stream`,

  /**
   * Post a media file
   */
  post: async (formData: FormData) => await request.formData<PostMediaResponse>(`/api/media`, formData),

  /**
   * get a media track info
   */
  track: async (payload: GetMediaTrackOptions) => {
    await request.get<GetMediaTrackResponse>(`/api/media/track/${payload.trackId}`, undefined, { next: { revalidate: 0 } });
  },
};
