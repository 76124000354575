import * as request from '@/packages/api/helpers';
import { CampaignSpotTrackType, CampaignSpotApprovalStatusType, CampaignType, CampaignSpotType, CampaignStatusType } from './campaign';
import { MentionItem } from 'react-mentions';

export type UpdateCampaignSpotOptions = {
  name?: string;
  spotId?: string;
  spotCategoryId?: string;
  division?: string;
  metadata?: string;
  createdAt?: string;
  modifiedAt?: string;
  mergedAudioAssetId?: string;
  tracks?: CampaignSpotTrackType[];
};

export type UpdateCampaignSpotApprovalStatusOptions = {
  spotId: string;
  approvalLevel: number;
  approvalStatus: CampaignSpotApprovalStatusType;
};

export type UpdateCampaignResponse = {} & CampaignType;
export type UpdateCampaignSpotApprovalStatusResponse = {};

export type PostSpotPayload = {
  campaignId: string;
  spot: {
    name: string;
    spotCategoryId: string;
    division: string;
    metadata: string;
  };
};
export type PostSpotResponse = {
  campaignId: string;
  spotId: string;
};

export type DeleteSpotPayload = {
  spotId: string;
};

export type DeleteSpotResponse = {
  id: string;
};

export type GetSpotPayload = {
  spotId: string;
};

export type GetSpotsPayload = {
  campaignGroupId?: string;
};

export type GetSpotsResponse = {
  spots: Array<CampaignSpotType>;
  campaignGroup: string;
  campaignGroupId: string;
  status: string;
};

export type GetSpotResponse = {} & CampaignSpotType;
export type GetSpotsByPeriodResponse = {
  spots: Array<CampaignSpotType>;
  status: CampaignStatusType;
};

export type GetSpotDownloadOptions = {
  campaignGroupId: string;
  finalAudioOnly?: boolean;
};

export type PostSpotMixingResponse = {
  campaignId: string;
  spotId: string;
  mergedAudioAssetId: string;
  errorMessage: string;
};

export type PostSpotReviewLevelPayload = {
  spotId: string;
  reviewLevelId: number;
  reviewLevelActionId: number;
};

export type PostSpotApprovePayload = {
  spotId: string;
  reviewLevelActionId: number;
};

export type PostCommentPayload = {
  spotId: string;
  content: string;
  mentions: MentionItem[];
};

export type PostCommentResponse = {
  spotId: string;
  commentId: string;
  createdBy: {
    userName: string;
    fullName: string;
  };
  createdAt: string;
  modifiedAt: string;
};

export const spot = {
  /**
   * Get a spot
   */
  get: async (payload: GetSpotPayload) => await request.get<GetSpotResponse>(`/api/spot/${payload.spotId}`, undefined, { next: { tags: ['spot'] } }),

  /**
   * Get a spot
   */
  getAll: async (payload: GetSpotsPayload) => await request.get<GetSpotsResponse>(`/api/spot/list`, payload, { next: { tags: ['spot'] } }),

  /**
   * Download all spots of a period
   */
  download: async (payload: GetSpotDownloadOptions) => await request.getFile(`/api/spot/download`, payload),

  /**
   * Create a spot
   */
  create: async (payload: PostSpotPayload) => await request.post<PostSpotResponse>(`/api/spot/`, payload, { invalidateTags: [`campaign`] }),

  /**
   * Delete
   */
  delete: async (payload: DeleteSpotPayload) => await request.del<DeleteSpotResponse>(`/api/spot/${payload.spotId}`, payload, { invalidateTags: [`campaign`] }),

  /**
   * Update a spot
   */
  update: async (payload: UpdateCampaignSpotOptions) => await request.put<UpdateCampaignResponse>(`/api/spot/${payload.spotId}`, payload, { invalidateTags: [`campaign`, `spot`] }),

  /**
   * Update a spot approval status
   */
  approvalStatus: async (payload: UpdateCampaignSpotApprovalStatusOptions) =>
    await request.put<UpdateCampaignSpotApprovalStatusResponse>(`/api/spot/${payload.spotId}/approval-status/${payload.approvalLevel}/${payload.approvalStatus}`, undefined, {
      invalidateTags: [`campaign`, `spot`],
    }),
  /**
   * Post mixed spot and logs
   */
  spotMixing: async (formData: FormData) => await request.formData<PostSpotMixingResponse>(`/api/spot/spot-mixing`, formData, { invalidateTags: [`campaign`, `spot`] }),

  /**
   * Set review level status
   */
  reviewLevel: async (payload: PostSpotReviewLevelPayload) => await request.post(`/api/spot/review-level`, payload, { invalidateTags: [`campaign`, `spot`] }),

  /**
   * Set last review level status from last review group
   */
  approve: async (payload: PostSpotApprovePayload) => await request.post(`/api/spot/approve`, payload, { invalidateTags: [`campaign`, `spot`] }),

  /**
   * Create a comment
   */
  comment: async (payload: PostCommentPayload) => await request.post<PostCommentResponse>(`/api/spot/comment`, payload, { invalidateTags: [`spot`] }),
};
