import * as request from '@/packages/api/helpers';

export type ConfigBrandType = {
  id?: string;
  name?: string;
  copyright?: string;
  logoPath?: string;
  designTokens?: string;
  // logo: File;
};

export type DictionaryKey =
  | 'common.welcome'
  | 'campaigns.cta.create'
  | 'campaign.create.group.label'
  | 'campaign.create.name.label'
  | 'campaign.create.release.label'
  | 'campaign.create.audioTemplate.label'
  | 'campaign.create.save.label'
  | 'campaign.create.cancel.label'
  | 'campaign.edit.save.label'
  | 'campaigns.table.campaign.group'
  | 'campaigns.table.campaign.name'
  | 'campaigns.table.campaign.status'
  | 'campaigns.table.campaign.spots'
  | 'campaigns.table.campaign.release'
  | 'campaigns.table.campaign.more'
  | 'campaigns.table.menu.download'
  | 'campaigns.table.menu.group'
  | 'campaigns.pagination.label'
  | 'campaign.create.title'
  | 'campaign.spot.table.name'
  | 'campaign.spot.table.division'
  | 'campaign.spot.table.category'
  | 'campaign.spot.table.comments'
  | 'campaign.spot.table.review'
  | 'campaign.menu.details'
  | 'campaign.menu.scripts'
  | 'campaign.menu.audios'
  | 'campaign.menu.delete'
  | 'spot.menu.delete'
  | 'spot.scripts.title'
  | 'spot.scripts.generate'
  | 'spot.scripts.generating'
  | 'spot.comments.title'
  | 'spot.comments.post'
  | 'admin.brand.dictionary.label.approvalLevel'
  | 'admin.brand.dictionary.default.approvalLevel'
  | 'review.level';

export type ConfigBrandDictionaryItemType = {
  key: DictionaryKey;
  value: string;
  id: string;
};

export type ConfigBrandDictionaryType = Array<ConfigBrandDictionaryItemType>;

export type GetConfigBrandResponse = {} & ConfigBrandType;
export type GetConfigBrandDictionaryResponse = {
  items: ConfigBrandDictionaryType;
};
export type UpdateConfigBrandPayload = {} & ConfigBrandType;
export type UpdateConfigBrandDictionaryItemPayload = {
  id: string;
  value: string;
};

export const brand = {
  /**
   * Get brand
   */
  get: async () => await request.get<GetConfigBrandResponse>('/api/config/tenant', undefined, { next: { tags: ['config:brand'] } }),
  /**
   * Update brand config
   */
  update: async (payload: UpdateConfigBrandPayload) => await request.put(`/api/config/tenant`, payload, { invalidateTags: ['config:brand'] }),
  dictionary: {
    /**
     * Get tenant dictionary
     */
    get: async () => await request.get<GetConfigBrandDictionaryResponse>('/api/config/tenant/dictionary', undefined, { next: { tags: ['config:brand'] } }),

    /**
     * Update tenant dictionary item
     */
    updateItem: async (payload: UpdateConfigBrandDictionaryItemPayload) => await request.put(`/api/config/tenant/dictionary/item`, payload, { invalidateTags: ['config:brand'] }),
  },
};
