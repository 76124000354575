import * as request from '@/packages/api/helpers';

export type ConfigSpotCategoryType = {
  id: string;
  name: string;
  title: string;
  value: string;
};
export type GetConfigSpotCategoryPayload = {
  id: string;
};
export type GetConfigSpotCategoryResponse = {} & ConfigSpotCategoryType;
export type GetConfigSpotCategoriesResponse = {
  spotCategories: ConfigSpotCategoryType[];
};
export type UpdateConfigSpotCategoryPayload = {} & ConfigSpotCategoryType;
export type UpdateConfigSpotCategoryResponse = {} & ConfigSpotCategoryType;
export type PostConfigSpotCategoryPayload = {
  name: string;
  title: string;
  value: string;
};
export type PostConfigSpotCategoryResponse = {
  id: string;
};

export const spotCategory = {
  /**
   * Get spot category
   */
  get: async (payload: GetConfigSpotCategoryPayload) => await request.get<GetConfigSpotCategoryResponse>('/api/config/spot-category', payload, { next: { tags: ['config:spot-category'] } }),
  /**
   * Get spot categories
   */
  getAll: async () => await request.get<GetConfigSpotCategoriesResponse>('/api/config/spot-category/list', undefined, { next: { tags: ['config:spot-category'] } }),
  /**
   * Update spot category
   */
  update: async (payload: UpdateConfigSpotCategoryPayload) => await request.put<UpdateConfigSpotCategoryResponse>(`/api/config/spot-category`, payload, { invalidateTags: ['config:spot-category'] }),
  /**
   * Create spot category
   */
  create: async (payload: PostConfigSpotCategoryPayload) => await request.post<PostConfigSpotCategoryResponse>(`/api/config/spot-category`, payload, { invalidateTags: ['config:spot-category'] }),
};
