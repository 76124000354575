import { reviewGroup } from './reviewGroup';
import { constants } from './constants';
import { apiKey } from './apiKey';
import { spotCategory } from './spotCategory';
import { campaignGroup } from './campaignGroup';
import { script } from './script';
import * as request from '@/packages/api/helpers';
import { brand, ConfigBrandType } from './brand';
import { audioTemplate, ConfigAudioTemplateType } from './audioTemplate';
import { tts, ConfigTTSProviderType } from './tts';

export type ConfigType = {
  brand?: ConfigBrandType;
  audioTemplates?: ConfigAudioTemplateType[];
  audioTemplateTrackTypes?: string[];
  ttsProviders?: ConfigTTSProviderType[];
};

export type GetConfigFullResponse = {} & ConfigType;

export const config = {
  brand,
  audioTemplate,
  tts,
  script,
  campaignGroup,
  spotCategory,
  apiKey,
  constants,
  reviewGroup,

  /**
   * new: Get tenant config
   */
  getFull: async () => await request.get<GetConfigFullResponse>('/api/config/full', undefined, { next: { tags: ['config'] } }),
};
