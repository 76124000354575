import * as request from '@/packages/api/helpers';

export type ConfigScriptMappingItemType = {
  id?: string;
  key?: string;
  scriptKey?: string;
  label?: string;
  extraction?: string;
  replace?: string;
  isMetadata?: boolean;
};

export type ConfigScriptType = {
  id?: string;
  name?: string;
  keys?: string;
  mapping?: ConfigScriptMappingItemType[];
  metadata?: ConfigScriptMappingItemType[];
};

export type GetConfigScriptResponse = {} & ConfigScriptType;
export type UpdateConfigScriptPayload = {} & ConfigScriptType;
export type UpdateConfigScriptResponse = {} & ConfigScriptType;

export type GetConfigScriptMappingItemPayload = { id: string };
export type GetConfigScriptMappingItemResponse = {} & ConfigScriptMappingItemType;
export type PutConfigScriptMappingItemPayload = { scriptId: string } & ConfigScriptMappingItemType;
export type PutConfigScriptMappingItemResponse = {} & ConfigScriptMappingItemType;
export type PostConfigScriptMappingItemPayload = {} & ConfigScriptMappingItemType;
export type PostConfigScriptMappingItemResponse = { id: string };

export const script = {
  /**
   * Get script config
   */
  get: async () => await request.get<GetConfigScriptResponse>('/api/config/script', undefined, { next: { tags: ['config:script'] } }),
  /**
   * Get script mapping item config
   */
  getMappingItem: async (payload: GetConfigScriptMappingItemPayload) =>
    await request.get<GetConfigScriptMappingItemResponse>('/api/config/script/mapping', payload, { next: { tags: ['config:script'] } }),
  /**
   * Update script config
   */
  update: async (payload: UpdateConfigScriptPayload) => await request.put<UpdateConfigScriptResponse>(`/api/config/script`, payload, { invalidateTags: ['config:script'] }),
  /**
   * Update script mapping item config
   */
  updateMappingItem: async (payload: PutConfigScriptMappingItemPayload) =>
    await request.put<PutConfigScriptMappingItemResponse>(`/api/config/script/mapping`, payload, { invalidateTags: ['config:script'] }),
  /**
   * Create script mapping item config
   */
  createMappingItem: async (payload: PostConfigScriptMappingItemPayload) =>
    await request.post<PostConfigScriptMappingItemResponse>(`/api/config/script/mapping`, payload, { invalidateTags: ['config:script'] }),
};
