import * as request from '@/packages/api/helpers';

export type ActiveTrackHistoryResponse = {
  spotId: string;
  trackId: string;
  trackHistoryId: string;
};

export type ActiveTrackHistoryPayload = {
  trackHistoryId: string;
};

export type ReuseTrackHistoryPayload = {
  trackHistoryId: string;
  spotIds: string[];
  applyFilters: boolean;
};

export const trackHistory = {
  /**
   * Active a spot's track
   */
  active: async (payload: ActiveTrackHistoryPayload) =>
    await request.post<ActiveTrackHistoryResponse>(`/api/trackhistory/${payload.trackHistoryId}/in-used`, undefined, { invalidateTags: ['spot', 'track-history'] }),
  /**
   * Reuse a spot's track
   */
  reuse: async (payload: ReuseTrackHistoryPayload) => await request.post(`/api/trackhistory/reuse`, payload, { invalidateTags: ['spot', 'track-history'] }),
};
