import * as request from '@/packages/api/helpers';

export type ConfigCampaignGroupType = {
  id: string;
  name: string;
  title: string;
  value: string;
};
export type GetConfigCampaignGroupPayload = {
  id: string;
};
export type GetConfigCampaignGroupResponse = {} & ConfigCampaignGroupType;
export type GetConfigCampaignGroupsResponse = {
  campaignGroups: ConfigCampaignGroupType[];
};
export type UpdateConfigCampaignGroupPayload = {} & ConfigCampaignGroupType;
export type UpdateConfigCampaignGroupResponse = {} & ConfigCampaignGroupType;
export type PostConfigCampaignGroupPayload = {
  name: string;
  title: string;
  value: string;
};
export type PostConfigCampaignGroupResponse = {
  id: string;
};

export const campaignGroup = {
  /**
   * Get campaign group
   */
  get: async (payload: GetConfigCampaignGroupPayload) => await request.get<GetConfigCampaignGroupResponse>('/api/config/campaign-group', payload, { next: { tags: ['config:campaign-group'] } }),
  /**
   * Get campaign groups
   */
  getAll: async () => await request.get<GetConfigCampaignGroupsResponse>('/api/config/campaign-group/list', undefined, { next: { tags: ['config:campaign-group'] } }),
  /**
   * Update campaign group
   */
  update: async (payload: UpdateConfigCampaignGroupPayload) =>
    await request.put<UpdateConfigCampaignGroupResponse>(`/api/config/campaign-group`, payload, { invalidateTags: ['config:campaign-group'] }),
  /**
   * Create campaign group
   */
  create: async (payload: PostConfigCampaignGroupPayload) => await request.post<PostConfigCampaignGroupResponse>(`/api/config/campaign-group`, payload, { invalidateTags: ['config:campaign-group'] }),
};
